<template>
  <div>
    <b-modal
      id="modal-form"
      centered
      hide-footer
      size="md"
      :title="modalTitle"
    >
      <b-card-text>
        <div
      >
          <b-alert
            v-if="errors.length > 0"
            variant="danger"
            show
          >
            <div class="alert-body">
              <p
                v-for="error in errors"
                :key="error"
              >
                {{ error }}
              </p>
            </div>
          </b-alert>
          <b-row>
            <b-col
              md="12"
              cols="12"
            >
              <b-form-group
                label="Title"
              >
                <b-form-input
                  v-model="form.title"
                  placeholder="Enter Title"
                />
              </b-form-group>
              <b-form-group
                label="Content"
              >
              <quill-editor
                v-model="form.content"
                :options="editorOption"
              />
              </b-form-group>
            </b-col>
        </b-row>
      </div>
      </b-card-text>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleSave()"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span>Simpan</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BAlert, BModal, VBModal, BCardText, BButton, BFormInput, BFormGroup, BCol, BRow
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive';
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BAlert,
    BModal,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BCol,
    BRow,

    quillEditor,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  
  props: {
    modalTitle: {
      type: String,
      default: 'Tambah Announcement',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    parentList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      errors: [],
      editorOption: {
        theme: 'snow',
      },
    }
  },
  methods: {
    async handleSave() {
      try {
        if (this.form.id) {
          await this.$http.put(`/v1/announcement/${this.form.id}`, this.form)
        } else {
          await this.$http.post('/v1/announcement', this.form)
        }

        this.$bvModal.hide('modal-form')
        this.$emit('success')
        this.$emit('refresh')
      } catch (error) {
        this.errors = error.response.data.errors
        this.$emit('error', error.response.data.messages)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
